<template>
  <b-col class="d-flex">
    <b-col>
      <AddVehicleButton
        :button-title="$t('add_vehicle')"
      />
      <VehicleDataCarousel
        v-if="vehicles.length > 0"
        :parent-index="currentIndex"
        :parent-vehicle-data="vehicles"
        @get-vehicles="getVehicles"
      />

      <ErrorDialog
        v-else-if="vehicles.length < 1"
        :error-message="
          `--
          ${$t('no_vehicle_found')}
        --`"
      />
    </b-col>

    <!-- ADD NEW VEHICLE MODAL -->
    <AddVehicleModal
      :parent-img="img ? img : []"
      :parent-submitted="submitted"
      @get-vehicles="getVehicles"
    />
  </b-col>
</template>

<script>
// import { getUserData } from "@/auth/utils";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import {
  AddVehicleButton, ErrorDialog, VehicleDataCarousel, AddVehicleModal,
} from '@/components'

export default {
  components: {
    AddVehicleButton,
    ErrorDialog,
    VehicleDataCarousel,
    AddVehicleModal,
  },
  data() {
    return {
      required,
      isFetchingVehicles: true,
      submitted: false,
      testSearch: false,
      test_data: {},
      empty: true,
      input: {},
      form: {
        insurance: {},
      },
      checked: '',
      vehicle: {},
      vehicles: [],
      show_data: false,
      currentIndex: 0,
      vId: 0,
      actionType: '',
      img: null,
    }
  },
  async created() {
    this.getVehicles()
  },

  methods: {
    getVehicles() {
      this.$http.get('/vehicles').then(response => {
        if (response.status === 200) {
          this.vehicles = response.data.data
          this.isFetchingVehicles = false
        }
      })
    },

    showToast(msg, variant = 'warning') {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Oopss!',
          icon: 'CoffeeIcon',
          variant,
          text: msg,
        },
      })
    },
    backButton() {
      this.test_data = {}
      this.test_result = {}
      this.img = null
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .hasCriteria {
    cursor: pointer !important;
  }
</style>
